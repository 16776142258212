// extracted by mini-css-extract-plugin
export var Behold = "Challanges-module--Behold--0e1cc";
export var accImg = "Challanges-module--accImg--04177";
export var approachImg = "Challanges-module--approachImg--e970e";
export var approachImg2 = "Challanges-module--approachImg2--d0ae0";
export var description = "Challanges-module--description--26851";
export var flexImg = "Challanges-module--flexImg--69da7";
export var head = "Challanges-module--head--19ff1";
export var heading = "Challanges-module--heading--4f517";
export var quesColor = "Challanges-module--quesColor--3178c";
export var quesColorSelected = "Challanges-module--quesColorSelected--1aa33";
export var ser = "Challanges-module--ser--9837a";
export var textColor = "Challanges-module--textColor--d2325";