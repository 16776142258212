import React from "react"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import * as styles from "./Disappear.module.scss"

const Disappear = ({ strapiData }) => {
  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <p className={styles.ser}>Solutions</p>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={`${styles.description} `}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className="gap-30">
            {strapiData?.cards?.map((e, i) => (
              <Col lg={4} className="gap-30">
                <div className={styles.success}>
                  <img
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/check_3_7_8e20c0afd7.png"
                    }
                    alt="check"
                    decoding="async"
                    loading="lazy"
                    className={styles.check}
                  />
                  <h3>{e?.title}</h3>
                  <p className={styles.dec}>{e?.subTitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Disappear
