// extracted by mini-css-extract-plugin
export var active = "Industries2-module--active--17ba3";
export var arrow = "Industries2-module--arrow--429a7";
export var description = "Industries2-module--description--9ffae";
export var heading = "Industries2-module--heading--673ce";
export var images = "Industries2-module--images--b2b23";
export var infoContainer = "Industries2-module--infoContainer--a1e8d";
export var section = "Industries2-module--section--4e5a0";
export var ser = "Industries2-module--ser--dc26b";
export var storyCard = "Industries2-module--storyCard--b6e19";
export var storyCardWrapper = "Industries2-module--storyCardWrapper--b4469";