// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--5ae0a";
export var bannerCon = "Banner-module--bannerCon--d0f84";
export var bannerHeading = "Banner-module--bannerHeading--64355";
export var btn = "Banner-module--btn--fd717";
export var description = "Banner-module--description--81e10";
export var fintech = "Banner-module--fintech--6d389";
export var freshprepHeading = "Banner-module--freshprepHeading--e1f11";
export var imageAnimation = "Banner-module--imageAnimation--2b938";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--c96a3";
export var subTitle = "Banner-module--subTitle--da53c";
export var topToBottomBounce = "Banner-module--topToBottomBounce--1cf40";