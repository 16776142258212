import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "../../components/android-app-development/android.scss"
import unforgetable from "../../images/android-App-Development/best-android-app-development-company.webp"
import * as styles from "./Challanges.module.scss"
import "./Challanges.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {

  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/minus_faqs_134d53dce5.svg"
              }
              className={styles.quesColorSelected}
              alt="icon"
              decoding="async"
              loading="lazy"
            />
          ) : (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/plus_1cd4c3a082.svg"
              }
              className={styles.quesColor}
              alt="icon"
              decoding="async"
              loading="lazy"
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Challanges = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.Behold}>
      <Container>
        <Row>
          <Col lg={6}>
            {/* <div className={styles.approachImg2}>
              <img
                decoding="async"
                loading="lazy"
                alt="process"
                src={unforgetable}
              />
            </div> */}
            <p className={styles.ser}>Challanges</p>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className="challangeAc">
              <Accordion defaultActiveKey={`ek-0`}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((e, i) => (
                    <Card key={i} border="light">
                      <ContextAwareToggle eventKey={`ek-${i}`}>
                        <div className={styles.accImg}>
                          <h3 className="mb-0 py-2 text-left" type="button">
                            {e?.title}
                          </h3>
                        </div>
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={`ek-${i}`}>
                        <Card.Body className={styles.textColor}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                      <hr style={{ margin: 0 }} />
                    </Card>
                  ))}
              </Accordion>
            </div>
          </Col>
          <Col lg={6} className={styles.flexImg}>
            <div className={styles.approachImg}>
              {/* <img
                decoding="async"
                loading="lazy"
                src={"https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19670_f421ebb625.svg"}
                // alt={strapiData?.secImages[0]?.alternativeText}
              /> */}
              {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                // src={story}
                decoding="async"
                loading="lazy"
              />
            )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Challanges
