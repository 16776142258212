import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Industry.module.scss"
const Industry = ({ strapiData }) => {
  return (
    <div className={`${styles.mernIndustryServices}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.cardBg}></div>

        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.slice(0, 8).map((e, i) => (
            <Col
              xl={3}
              md={6}
              sm={6}
              xs={12}
              key={i}
              className="gap-30 d-flex justify-content-center"
            >
              <div className={styles.card1}>
                <div className="d-flex align-items-center">
                  <div>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{ height: "60px", width: "60px", margin: "0px" }}
                    />
                  </div>
                  <div>
                    <Link to={e?.buttons[0]?.url}>
                      <h3>{e?.title}</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Industry
