import React from "react"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import * as styles from "./Solutions.module.scss"

const Solutions = ({ strapiData }) => {
  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <p className={styles.ser}>Services</p>
          <h2 className={styles.heading}>
            Software Development Services For Forward Planners
          </h2>
          <Row className="gap-30">
            {strapiData?.cards?.map((e, i) => (
              <Col lg={4} md={6} sm={12} className="gap-30 d-flex">
                <div className={styles.success}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", }}
                  />
                  <div key={i} className={styles.portfoliosLogos}>
                    <h3>{e?.title}</h3>
                    <p className={styles.dec}>{e?.subTitle}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Solutions
