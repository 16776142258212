import { Link } from "gatsby"
import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Industries2.module.scss"

export default function Stories({ strapiData }) {
  const data = [
    {
      name: "Fintech",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Fintech_33de5f0e88.webp",
      button: "/fintech/",
    },
    {
      name: "E-commerce",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Ecommerce_f9ea9a3a8f.webp",
      button: "/e-commerce/",
    },
    {
      name: "Healthcare",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Healthcare_04064e51af.webp",
      button: "/healthcare/",
    },
    {
      name: "Food & Grocery",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Food_and_Grocery_2d7efe8f96.webp",
      button: "/food-groceries/",
    },
    {
      name: "Education",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Education_f5dd807bbd.webp",
      button: "/education/",
    },
    {
      name: "Travel & Tourism",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Travel_and_Tourism_ea088028e3.webp",
      button: "/travel-tourism/",
    },
    {
      name: "Real Estate",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Real_Estate_b7712672ae.webp",
      button: "/contact-us/",
    },
    {
      name: "On-demand",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/On_demand_753072d087.webp",
      button: "/on-demand-services/",
    },
  ]

  const [hoveredStory, setHoveredStory] = useState(data[0])

  return (
    <div className={styles.section}>
      <Container>
        <p className={styles.ser}>Industries</p>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{
            __html: strapiData?.title,
          }}
        />
        <p
          className={`${styles.description} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        />
        <Row className="align-items-center justify-content-center">
          <div className={styles.storyCardWrapper}>
            {data.map(el => (
              <Link to={el?.button}>
                <div
                  key={`story_${el.name}`}
                  className={styles.storyCard}
                  onMouseEnter={() => setHoveredStory(el)}
                  onMouseLeave={() => setHoveredStory(data[0])}
                >
                  <div className={styles.infoContainer}>
                    <p>{el.name}</p>
                    {hoveredStory.name === el.name && (
                      <img
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_809f86693c.svg"
                        decoding="async"
                        loading="lazy"
                        className={styles.arrow}
                        alt="Arrow"
                      />
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div>
            <div className={`${styles.columnTopBannerImg2} d-md-block`}>
              {hoveredStory && (
                <div className={styles.subHeading}>
                  <img
                    src={hoveredStory.image1}
                    decoding="async"
                    style={{ height: "668px", width: "905px" }}
                    alt={hoveredStory.name}
                  />
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}
