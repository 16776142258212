// extracted by mini-css-extract-plugin
export var SliderWidth = "CaseStudies-module--SliderWidth--01e3e";
export var Trust = "CaseStudies-module--Trust--3a24e";
export var bottomLine = "CaseStudies-module--bottomLine--1257d";
export var btn = "CaseStudies-module--btn--47873";
export var btn2 = "CaseStudies-module--btn2--dac5d";
export var clientImgs = "CaseStudies-module--clientImgs--6e31d";
export var content = "CaseStudies-module--content--aa7bd";
export var devOpsRefineImages = "CaseStudies-module--devOpsRefineImages--87783";
export var headSearch = "CaseStudies-module--headSearch--51899";
export var heading = "CaseStudies-module--heading--c4628";
export var logoBtn = "CaseStudies-module--logoBtn--d5db4";
export var logos = "CaseStudies-module--logos--bb707";
export var overly = "CaseStudies-module--overly--93b45";
export var portfolios = "CaseStudies-module--portfolios--60c5b";
export var ser = "CaseStudies-module--ser--81c6f";
export var talkBtn = "CaseStudies-module--talkBtn--f5520";
export var talkBtn2 = "CaseStudies-module--talkBtn2--a0771";
export var topLine = "CaseStudies-module--topLine--c5807";